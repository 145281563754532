////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(12, 32, 38, 1);
}
.bg-fourthColor {
  background-color: rgba(148, 198, 165, 1);
}
.bg-fifthColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-sixthColor {
  background-color: rgba(59, 161, 161, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 255, 255, 1);
}
